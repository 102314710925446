const SYSTEM_ADMINISTRATION_MOCKS = {
    USERS_LIST: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      leftMenu: {
        close: "Sign Off",
        items: [
          {
            app: false,
            action: "#homePageFromSignIn",
            name: "Home",
            icon: "fa-home",
          },
          {
            app: false,
            action: "#depositAccountPage",
            name: "Deposit Accounts",
          },
          {
            app: false,
            action: "#creditAccountPage",
            name: "Credit Accounts",
          },
          {
            app: false,
            action: "#approvalsPage",
            name: "Approvals",
          },
          {
            app: false,
            action: "#transferMenuPage",
            name: "Transfers and Payments",
          },
          {
            app: false,
            action: "#adminFunctionMenuPage",
            name: "System Admin Functions",
          },
          {
            app: false,
            action: "#positivePayPage",
            name: "ARP - Positive Pay",
          },
          {
            app: false,
            action: "#messagesMenuPage",
            name: "Messages",
          },
          {
            app: false,
            action: "#mobileTokenManagementSummaryPage",
            name: "Mobile Token Management",
          },
          {
            app: false,
            action: "#userAdministrationPage",
            name: "User Administration",
          },
          {
            app: false,
            action: "#help/issueNewPasswordHelpPage",
            name: "Help: Issue New Password",
          },
        ],
      },
      rightMenu: {
        includeLocales: true,
        close: "Close",
        items: [
          {
            app: false,
            action: "#contactUsPage",
            name: "Contact Us",
            icon: "fa-phone",
          },
          {
            app: false,
            action: "#faqsPage",
            name: "FAQs",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#quickStartGuidePage",
            name: "Quick Start Guide",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#requireUserAgreementPage",
            name: "CitiBusiness&#174; Online User Agreement",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#privacyPage",
            name: "Privacy Statement",
            icon: "fa-info-circle",
          },
        ],
      },
      success: true,
      body: {
        header: "Issue New Password",
        adminIssuePasswordNote:
          "Enter your password and click Submit to generate a temporary password for the selected user",
        asofHeader: "Date ",
        password: "Enter Password",
        cancel: "Cancel",
        submit: "Submit",
        ok: "OK",
        newPasswordIssueText: "is about to be issued a new password",
        userSelectionText: "User Selection",
        adminUserFirstPageMessage:
          "Select the user whose system access you want to change.",
        adminUserSecondPageMessage:
          "Suspended users must be reactivated within one year of the date of suspension.",
        suspensionAndReactivationDate: "Suspension and Reactivation Date",
        reactivationDate: "Reactivation Date :",
        suspensionDateRequired: "Suspension Date (Required):",
        sortByUserName: "Sort by User Name",
        sortByUserId: "Sort by User Id",
        selectUser: "Select User",
        userInfo: [
          "015 - bharathi balasubramaniya",
          "016 - VA-USER016 TESTER",
          "034 - Sudipto3 Roy",
          "038 - Amy Wallaert2",
          "041 - hard token",
          "066 - Kelly User",
          "082 - ABHIMANYU TEST",
          "084 - DEBRA FIGGERS",
          "090 - Test NoDel",
          "091 - USER2 TOKEN",
          "100 - 100 ROHAN",
          "103 - NK NKDEL",
          "106 - VA 68 Tester 68",
          "116 - REBECCA TEST",
          "135 - GEORGE P",
          "179 - Pratibha Goyal",
          "194 - VA TESTER5",
          "206 - TERI GUMA II",
          "208 - TERI GUMA",
          "215 - PRI TESTT",
          "224 - FARID AHMED",
          "225 - VA TESTER 225",
          "227 - VA TESTER 227",
          "228 - SUDIPTO ROY",
          "229 - Amy WallaertTest",
          "236 - Nihit Baluni",
          "238 - Pradeep Kumar",
          "244 - test va asd",
          "276 - test va 418",
          "290 - USER CS",
          "293 - TESTER 293 BOTTOMLINE",
          "294 - VA TESTER1",
          "296 - VA TESTER2",
          "298 - VA TESTER298",
          "300 - VA2 TESTER300",
          "310 - VA TESTER BOTTOMLINE",
          "311 - testva 425",
          "346 - SRINI MUKKUHT",
          "362 - YU SANITYTEST",
          "369 - REBECCA DONT DELETE",
          "371 - REBECCA TEST",
          "375 - New User Soft Token",
          "415 - VA-USER415 TESTER",
          "436 - VA TESTER 436",
          "444 - SHANTANU MOBILE USER",
          "449 - ARNO DONT DELETE",
          "456 - Rebecca HardToken",
          "467 - Ram K",
          "468 - 468 ROHAN GTPS",
          "469 - Mounika J",
          "495 - Kiru Shan",
          "498 - VA TESTER 498",
          "515 - PRIMEON VATESTER6",
          "519 - vanotoken test",
          "531 - Vijaya Y",
          "545 - DEBRA FIGGERS-545",
          "546 - testasd asd",
          "547 - test va530",
          "552 - vatest new530",
          "555 - Mobile TokenTest",
          "556 - vatime vatest",
          "563 - Vicky dont del",
          "568 - Manisha NoTokenn",
          "580 - TEST 0602",
          "591 - Ram Mekala",
          "601 - RamG Test",
          "607 - vapost va",
          "614 - testva0608 test",
          "615 - vatester va0608",
          "624 - test06092 test",
          "630 - vatester61 va",
          "633 - Shikha Sinha",
          "657 - VIVEK ANAND",
          "685 - TOKEN NO",
          "692 - retest621 va",
          "697 - va622 va",
          "718 - TESTER 718 BOTTOMLINE",
          "719 - TESTER 719 BOTTOMLINE",
          "737 - Vijaya Test2",
          "749 - DONT Delet Abhimanyu",
          "760 - ENTITLE USERDONTDEL",
          "794 - Manisha MobToen",
          "795 - Manisha HardTo",
          "796 - ROHAN 796MOBTOKEN",
          "817 - ravi test",
          "830 - Ahmad Safdar1",
          "833 - sonali pohekar",
          "845 - mariann barrios",
          "852 - vijaya test",
          "884 - SAMIR GAJIWALA",
          "886 - CP TEST2",
          "902 - retest725 test",
          "903 - test7252 test",
          "906 - retest0726 test",
          "983 - JAMES VA TESTER983",
          "991 - JAMES VA TESTER991",
        ],
        errors: [
          {
            id: "theSecurityInformationYouEnteredIsNotValid",
            message:
              "The security information you entered is not valid.  Please try again.",
          },
          {
            id: "pleaseEnterASsuspensionDateFirst",
            message: "Please enter a suspension date first.",
          },
          {
            id: "suspensionReactivationDateHasBeenCleared",
            message: "Suspension/Reactivation date(s) has been cleared.",
          },
          {
            id: "usersCanBeSuspendedUpTo90DaysInAdvanceOfTheCurrentDatePleaseEnterValidDate",
            message:
              "Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.",
          },
          {
            id: "youHaveEnteredAnInvalidSuspensionDate",
            message: "You have entered an invalid suspension date.",
          },
          {
            id: "youHaveEnteredAnInvalidReactivationDate",
            message: "You have entered an invalid reactivation date.",
          },
          {
            id: "selectAnOption",
            message: "Select an option",
          },
          {
            id: "pleaseEnterAReactivationDateThatIsWithinOneYearOfTheSuspensionDate",
            message:
              "Please enter a reactivation date that is within one year of the suspension date.",
          },
          {
            id: "theReactivationDateCannotBeTheSameAsTheSuspensionDate",
            message:
              "The reactivation date cannot be the same as the suspension date.",
          },
        ],
      },
    },
  
    USERS_LIST_SORT_BY_USER_NAME: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      leftMenu: {
        close: "Sign Off",
        items: [
          {
            app: false,
            action: "#homePageFromSignIn",
            name: "Home",
            icon: "fa-home",
          },
          {
            app: false,
            action: "#depositAccountPage",
            name: "Deposit Accounts",
          },
          {
            app: false,
            action: "#creditAccountPage",
            name: "Credit Accounts",
          },
          {
            app: false,
            action: "#approvalsPage",
            name: "Approvals",
          },
          {
            app: false,
            action: "#transferMenuPage",
            name: "Transfers and Payments",
          },
          {
            app: false,
            action: "#adminFunctionMenuPage",
            name: "System Admin Functions",
          },
          {
            app: false,
            action: "#positivePayPage",
            name: "ARP - Positive Pay",
          },
          {
            app: false,
            action: "#messagesMenuPage",
            name: "Messages",
          },
          {
            app: false,
            action: "#mobileTokenManagementSummaryPage",
            name: "Mobile Token Management",
          },
          {
            app: false,
            action: "#userAdministrationPage",
            name: "User Administration",
          },
          {
            app: false,
            action: "#help/issueNewPasswordHelpPage",
            name: "Help: Issue New Password",
          },
        ],
      },
      rightMenu: {
        includeLocales: true,
        close: "Close",
        items: [
          {
            app: false,
            action: "#contactUsPage",
            name: "Contact Us",
            icon: "fa-phone",
          },
          {
            app: false,
            action: "#faqsPage",
            name: "FAQs",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#quickStartGuidePage",
            name: "Quick Start Guide",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#requireUserAgreementPage",
            name: "CitiBusiness&#174; Online User Agreement",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#privacyPage",
            name: "Privacy Statement",
            icon: "fa-info-circle",
          },
        ],
      },
      success: true,
      body: {
        header: "Issue New Password",
        adminIssuePasswordNote:
          "Enter your password and click Submit to generate a temporary password for the selected user",
        asofHeader: "Date ",
        password: "Enter Password",
        cancel: "Cancel",
        submit: "Submit",
        ok: "OK",
        newPasswordIssueText: "is about to be issued a new password",
        userSelectionText: "User Selection",
        adminUserFirstPageMessage:
          "Select the user whose system access you want to change.",
        adminUserSecondPageMessage:
          "Suspended users must be reactivated within one year of the date of suspension.",
        suspensionAndReactivationDate: "Suspension and Reactivation Date",
        reactivationDate: "Reactivation Date :",
        suspensionDateRequired: "Suspension Date (Required):",
        sortByUserName: "Sort by User Name",
        sortByUserId: "Sort by User Id",
        selectUser: "Select User",
        userInfo: [
          "580 - TEST 0602",
          "276 - test va 418",
          "311 - testva 425",
          "796 - ROHAN 796MOBTOKEN",
          "749 - DONT Delet Abhimanyu",
          "224 - FARID AHMED",
          "657 - VIVEK ANAND",
          "244 - test va asd",
          "546 - testasd asd",
          "015 - bharathi balasubramaniya",
          "236 - Nihit Baluni",
          "845 - mariann barrios",
          "293 - TESTER 293 BOTTOMLINE",
          "718 - TESTER 718 BOTTOMLINE",
          "719 - TESTER 719 BOTTOMLINE",
          "310 - VA TESTER BOTTOMLINE",
          "290 - USER CS",
          "563 - Vicky dont del",
          "449 - ARNO DONT DELETE",
          "369 - REBECCA DONT DELETE",
          "084 - DEBRA FIGGERS",
          "545 - DEBRA FIGGERS-545",
          "884 - SAMIR GAJIWALA",
          "179 - Pratibha Goyal",
          "468 - 468 ROHAN GTPS",
          "206 - TERI GUMA II",
          "208 - TERI GUMA",
          "795 - Manisha HardTo",
          "456 - Rebecca HardToken",
          "469 - Mounika J",
          "467 - Ram K",
          "238 - Pradeep Kumar",
          "591 - Ram Mekala",
          "444 - SHANTANU MOBILE USER",
          "794 - Manisha MobToen",
          "346 - SRINI MUKKUHT",
          "552 - vatest new530",
          "103 - NK NKDEL",
          "685 - TOKEN NO",
          "090 - Test NoDel",
          "568 - Manisha NoTokenn",
          "135 - GEORGE P",
          "833 - sonali pohekar",
          "100 - 100 ROHAN",
          "228 - SUDIPTO ROY",
          "034 - Sudipto3 Roy",
          "830 - Ahmad Safdar1",
          "362 - YU SANITYTEST",
          "495 - Kiru Shan",
          "633 - Shikha Sinha",
          "375 - New User Soft Token",
          "082 - ABHIMANYU TEST",
          "601 - RamG Test",
          "817 - ravi test",
          "371 - REBECCA TEST",
          "906 - retest0726 test",
          "902 - retest725 test",
          "624 - test06092 test",
          "903 - test7252 test",
          "614 - testva0608 test",
          "519 - vanotoken test",
          "852 - vijaya test",
          "886 - CP TEST2",
          "737 - Vijaya Test2",
          "225 - VA TESTER 225",
          "227 - VA TESTER 227",
          "436 - VA TESTER 436",
          "498 - VA TESTER 498",
          "106 - VA 68 Tester 68",
          "016 - VA-USER016 TESTER",
          "415 - VA-USER415 TESTER",
          "294 - VA TESTER1",
          "296 - VA TESTER2",
          "298 - VA TESTER298",
          "300 - VA2 TESTER300",
          "194 - VA TESTER5",
          "215 - PRI TESTT",
          "041 - hard token",
          "091 - USER2 TOKEN",
          "555 - Mobile TokenTest",
          "066 - Kelly User",
          "760 - ENTITLE USERDONTDEL",
          "692 - retest621 va",
          "983 - JAMES VA TESTER983",
          "991 - JAMES VA TESTER991",
          "697 - va622 va",
          "607 - vapost va",
          "630 - vatester61 va",
          "615 - vatester va0608",
          "547 - test va530",
          "556 - vatime vatest",
          "515 - PRIMEON VATESTER6",
          "038 - Amy Wallaert2",
          "229 - Amy WallaertTest",
          "531 - Vijaya Y",
        ],
        errors: [
          {
            id: "theSecurityInformationYouEnteredIsNotValid",
            message:
              "The security information you entered is not valid.  Please try again.",
          },
          {
            id: "pleaseEnterASsuspensionDateFirst",
            message: "Please enter a suspension date first.",
          },
          {
            id: "suspensionReactivationDateHasBeenCleared",
            message: "Suspension/Reactivation date(s) has been cleared.",
          },
          {
            id: "usersCanBeSuspendedUpTo90DaysInAdvanceOfTheCurrentDatePleaseEnterValidDate",
            message:
              "Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.",
          },
          {
            id: "youHaveEnteredAnInvalidSuspensionDate",
            message: "You have entered an invalid suspension date.",
          },
          {
            id: "youHaveEnteredAnInvalidReactivationDate",
            message: "You have entered an invalid reactivation date.",
          },
          {
            id: "selectAnOption",
            message: "Select an option",
          },
          {
            id: "pleaseEnterAReactivationDateThatIsWithinOneYearOfTheSuspensionDate",
            message:
              "Please enter a reactivation date that is within one year of the suspension date.",
          },
          {
            id: "theReactivationDateCannotBeTheSameAsTheSuspensionDate",
            message:
              "The reactivation date cannot be the same as the suspension date.",
          },
        ],
      },
    },
  
    USERS_LIST_SUSPEND_REACTIVATE_USER: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      success: true,
      body: {
        header: "Issue New Password",
        adminIssuePasswordNote:
          "Enter your password and click Submit to generate a temporary password for the selected user",
        asofHeader: "Date ",
        password: "Enter Password",
        cancel: "Cancel",
        submit: "Submit",
        ok: "OK",
        newPasswordIssueText: "is about to be issued a new password",
        userSelectionText: "User Selection",
        adminUserFirstPageMessage:
          "Select the user whose system access you want to change.",
        adminUserSecondPageMessage:
          "Suspended users must be reactivated within one year of the date of suspension.",
        suspensionAndReactivationDate: "Suspension and Reactivation Date",
        reactivationDate: "Reactivation Date :",
        suspensionDateRequired: "Suspension Date (Required):",
        sortByUserName: "Sort by User Name",
        sortByUserId: "Sort by User Id",
        selectUser: "Select User",
        userInfo: [
          "015 - bharathi balasubramaniya - Active",
          "016 - VA-USER016 TESTER - Active",
          "019 - VICKY MOBILE DONT DEL - Suspended",
          "034 - Sudipto3 Roy - Active",
          "038 - Amy Wallaert2 - Active",
          "041 - hard token - Active",
          "066 - Kelly User - Active",
          "082 - ABHIMANYU TEST - Active",
          "084 - DEBRA FIGGERS - Active",
          "090 - Test NoDel - Active",
          "091 - USER2 TOKEN - Active",
          "100 - 100 ROHAN - Active",
          "103 - NK NKDEL - Active",
          "106 - VA 68 Tester 68 - Active",
          "116 - REBECCA TEST - Active",
          "135 - GEORGE P - Active",
          "179 - Pratibha Goyal - Active",
          "194 - VA TESTER5 - Active",
          "206 - TERI GUMA II - Active",
          "208 - TERI GUMA - Active",
          "215 - PRI TESTT - Active",
          "224 - FARID AHMED - Active",
          "225 - VA TESTER 225 - Active",
          "227 - VA TESTER 227 - Active",
          "228 - SUDIPTO ROY - Active",
          "229 - Amy WallaertTest - Active",
          "236 - Nihit Baluni - Active",
          "238 - Pradeep Kumar - Active",
          "244 - test va asd - Active",
          "276 - test va 418 - Active",
          "290 - USER CS - Active",
          "293 - TESTER 293 BOTTOMLINE - Active",
          "294 - VA TESTER1 - Active",
          "296 - VA TESTER2 - Active",
          "298 - VA TESTER298 - Active",
          "300 - VA2 TESTER300 - Active",
          "310 - VA TESTER BOTTOMLINE - Active",
          "311 - testva 425 - Active",
          "346 - SRINI MUKKUHT - Active",
          "362 - YU SANITYTEST - Active",
          "369 - REBECCA DONT DELETE - Active",
          "371 - REBECCA TEST - Active",
          "375 - New User Soft Token - Active",
          "415 - VA-USER415 TESTER - Active",
          "436 - VA TESTER 436 - Active",
          "444 - SHANTANU MOBILE USER - Active",
          "449 - ARNO DONT DELETE - Active",
          "456 - Rebecca HardToken - Active",
          "467 - Ram K - Active",
          "468 - 468 ROHAN GTPS - Active",
          "469 - Mounika J - Active",
          "495 - Kiru Shan - Active",
          "498 - VA TESTER 498 - Active",
          "515 - PRIMEON VATESTER6 - Active",
          "519 - vanotoken test - Active",
          "531 - Vijaya Y - Active",
          "545 - DEBRA FIGGERS-545 - Active",
          "546 - testasd asd - Active",
          "547 - test va530 - Active",
          "552 - vatest new530 - Active",
          "555 - Mobile TokenTest - Active",
          "556 - vatime vatest - Active",
          "563 - Vicky dont del - Active",
          "568 - Manisha NoTokenn - Active",
          "580 - TEST 0602 - Active",
          "591 - Ram Mekala - Active",
          "601 - RamG Test - Active",
          "607 - vapost va - Active",
          "614 - testva0608 test - Active",
          "615 - vatester va0608 - Active",
          "624 - test06092 test - Active",
          "630 - vatester61 va - Active",
          "633 - Shikha Sinha - Active",
          "657 - VIVEK ANAND - Active",
          "685 - TOKEN NO - Active",
          "692 - retest621 va - Active",
          "697 - va622 va - Active",
          "718 - TESTER 718 BOTTOMLINE - Active",
          "719 - TESTER 719 BOTTOMLINE - Active",
          "737 - Vijaya Test2 - Active",
          "749 - DONT Delet Abhimanyu - Active",
          "760 - ENTITLE USERDONTDEL - Active",
          "794 - Manisha MobToen - Active",
          "795 - Manisha HardTo - Active",
          "796 - ROHAN 796MOBTOKEN - Active",
          "817 - ravi test - Active",
          "830 - Ahmad Safdar1 - Active",
          "833 - sonali pohekar - Active",
          "845 - mariann barrios - Active",
          "852 - vijaya test - Active",
          "884 - SAMIR GAJIWALA - Active",
          "886 - CP TEST2 - Active",
          "902 - retest725 test - Active",
          "903 - test7252 test - Active",
          "906 - retest0726 test - Active",
          "983 - JAMES VA TESTER983 - Active",
          "991 - JAMES VA TESTER991 - Active",
        ],
        errors: [
          {
            id: "theSecurityInformationYouEnteredIsNotValid",
            message:
              "The security information you entered is not valid.  Please try again.",
          },
          {
            id: "pleaseEnterASsuspensionDateFirst",
            message: "Please enter a suspension date first.",
          },
          {
            id: "suspensionReactivationDateHasBeenCleared",
            message: "Suspension/Reactivation date(s) has been cleared.",
          },
          {
            id: "usersCanBeSuspendedUpTo90DaysInAdvanceOfTheCurrentDatePleaseEnterValidDate",
            message:
              "Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.",
          },
          {
            id: "youHaveEnteredAnInvalidSuspensionDate",
            message: "You have entered an invalid suspension date.",
          },
          {
            id: "youHaveEnteredAnInvalidReactivationDate",
            message: "You have entered an invalid reactivation date.",
          },
          {
            id: "selectAnOption",
            message: "Select an option",
          },
          {
            id: "pleaseEnterAReactivationDateThatIsWithinOneYearOfTheSuspensionDate",
            message:
              "Please enter a reactivation date that is within one year of the suspension date.",
          },
          {
            id: "theReactivationDateCannotBeTheSameAsTheSuspensionDate",
            message:
              "The reactivation date cannot be the same as the suspension date.",
          },
        ],
      },
    },
  
    ISSUE_NEW_PASSWORD_SUCCESS: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      leftMenu: {
        close: "Sign Off",
        items: [
          {
            app: false,
            action: "#homePageFromSignIn",
            name: "Home",
            icon: "fa-home",
          },
          {
            app: false,
            action: "#depositAccountPage",
            name: "Deposit Accounts",
          },
          {
            app: false,
            action: "#creditAccountPage",
            name: "Credit Accounts",
          },
          {
            app: false,
            action: "#approvalsPage",
            name: "Approvals",
          },
          {
            app: false,
            action: "#transferMenuPage",
            name: "Transfers and Payments",
          },
          {
            app: false,
            action: "#adminFunctionMenuPage",
            name: "System Admin Functions",
          },
          {
            app: false,
            action: "#positivePayPage",
            name: "ARP - Positive Pay",
          },
          {
            app: false,
            action: "#messagesMenuPage",
            name: "Messages",
          },
          {
            app: false,
            action: "#mobileTokenManagementSummaryPage",
            name: "Mobile Token Management",
          },
          {
            app: false,
            action: "#userAdministrationPage",
            name: "User Administration",
          },
          {
            app: false,
            action: "#help/adminFunctionsHelpPage",
            name: "Help: Admin Functions",
          },
        ],
      },
      rightMenu: {
        includeLocales: true,
        close: "Close",
        items: [
          {
            app: false,
            action: "#contactUsPage",
            name: "Contact Us",
            icon: "fa-phone",
          },
          {
            app: false,
            action: "#faqsPage",
            name: "FAQs",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#quickStartGuidePage",
            name: "Quick Start Guide",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#requireUserAgreementPage",
            name: "CitiBusiness&#174; Online User Agreement",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#privacyPage",
            name: "Privacy Statement",
            icon: "fa-info-circle",
          },
        ],
      },
      success: true,
      body: {
        header: "Issue New Password",
        ok: "OK",
        userName: "User Name",
        userId: "User Id",
        userInfo: {
          responseCode: "SUCCESS",
          success: true,
          signOut: false,
          userName: "TERI GUMA",
          userId: "208",
          emailSentTo: "p************l@citi.com",
        },
        status: "A new password has been issued",
        temporaryPassword: "Temporary Password Sent To :",
        issuePasswordConfirmText: "A new password has been issued",
        newPasswordRequestPlacedOnPendingQueue:
          "The request has been added to the System Administrator Approval Queue and is pending approval.",
        statusText: "Status",
        errors: [
          {
            id: "message-error",
            message: "Password Required",
          },
        ],
      },
    },
  
    ISSUE_NEW_PASSWORD_FAILURE: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      leftMenu: {
        close: "Sign Off",
        items: [
          {
            app: false,
            action: "#homePageFromSignIn",
            name: "Home",
            icon: "fa-home",
          },
          {
            app: false,
            action: "#depositAccountPage",
            name: "Deposit Accounts",
          },
          {
            app: false,
            action: "#creditAccountPage",
            name: "Credit Accounts",
          },
          {
            app: false,
            action: "#approvalsPage",
            name: "Approvals",
          },
          {
            app: false,
            action: "#transferMenuPage",
            name: "Transfers and Payments",
          },
          {
            app: false,
            action: "#adminFunctionMenuPage",
            name: "System Admin Functions",
          },
          {
            app: false,
            action: "#positivePayPage",
            name: "ARP - Positive Pay",
          },
          {
            app: false,
            action: "#messagesMenuPage",
            name: "Messages",
          },
          {
            app: false,
            action: "#mobileTokenManagementSummaryPage",
            name: "Mobile Token Management",
          },
          {
            app: false,
            action: "#userAdministrationPage",
            name: "User Administration",
          },
          {
            app: false,
            action: "#help/adminFunctionsHelpPage",
            name: "Help: Admin Functions",
          },
        ],
      },
      rightMenu: {
        includeLocales: true,
        close: "Close",
        items: [
          {
            app: false,
            action: "#contactUsPage",
            name: "Contact Us",
            icon: "fa-phone",
          },
          {
            app: false,
            action: "#faqsPage",
            name: "FAQs",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#quickStartGuidePage",
            name: "Quick Start Guide",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#requireUserAgreementPage",
            name: "CitiBusiness&#174; Online User Agreement",
            icon: "fa-info-circle",
          },
          {
            app: false,
            action: "#privacyPage",
            name: "Privacy Statement",
            icon: "fa-info-circle",
          },
        ],
      },
      success: false,
      genericException: false,
      errors: [
        {
          name: "newPasswordRequestAlreadyOutstanding-exception",
          value:
            "There is already an outstanding new password request for this user.",
        },
      ],
      body: {
        header: "Issue New Password",
        ok: "OK",
        userName: "User Name",
        userId: "User Id",
        temporaryPassword: "Temporary Password Sent To :",
        issuePasswordConfirmText: "A new password has been issued",
        newPasswordRequestPlacedOnPendingQueue:
          "The request has been added to the System Administrator Approval Queue and is pending approval.",
        statusText: "Status",
        errors: [
          {
            id: "message-error",
            message: "Password Required",
          },
        ],
      },
    },
  
    UPDATE_SUSPEND_USER_OLD_DETAILS: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      success: true,
      body: {
        header: "Issue New Password",
        ok: "OK",
        userName: "User Name",
        userId: "User Id",
        userInfo: {
          userId: "041",
          firstName: "REBECCA   ",
          lastName: "DONT DELETE         ",
          suspendDate: "12/02/2023",
          reactivateDate: "12/03/2023",
          suspended: false,
          numOfDaysFromTodaySuspendedDate: 0,
          numOfDaysFromTodayReactivationDate: 1,
        },
        issuePasswordConfirmText: "A new password has been issued",
        errors: [
          {
            id: "pleaseEnterASsuspensionDateFirst",
            message: "Please enter a suspension date first.",
          },
          {
            id: "suspensionReactivationDateHasBeenCleared",
            message: "Suspension/Reactivation date(s) has been cleared.",
          },
          {
            id: "usersCanBeSuspendedUpTo90DaysInAdvanceOfTheCurrentDatePleaseEnterValidDate",
            message:
              "Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.",
          },
          {
            id: "youHaveEnteredAnInvalidSuspensionDate",
            message: "You have entered an invalid suspension date.",
          },
          {
            id: "youHaveEnteredAnInvalidReactivationDate",
            message: "You have entered an invalid reactivation date.",
          },
          {
            id: "pleaseEnterAReactivationDateThatIsWithinOneYearOfTheSuspensionDate",
            message:
              "Please enter a reactivation date that is within one year of the suspension date.",
          },
          {
            id: "theReactivationDateCannotBeTheSameAsTheSuspensionDate",
            message:
              "The reactivation date cannot be the same as the suspension date.",
          },
        ],
      },
    },
  
    UPDATE_SUSPEND_USER_OLD_DETAILS_EMPTY: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      success: true,
      body: {
        header: "Issue New Password",
        ok: "OK",
        userName: "User Name",
        userId: "User Id",
        userInfo: {
          userId: "369",
          firstName: "REBECCA   ",
          lastName: "DONT DELETE         ",
          suspendDate: "",
          reactivateDate: "",
          suspended: false,
          numOfDaysFromTodaySuspendedDate: 0,
          numOfDaysFromTodayReactivationDate: 0,
        },
        issuePasswordConfirmText: "A new password has been issued",
        errors: [
          {
            id: "pleaseEnterASsuspensionDateFirst",
            message: "Please enter a suspension date first.",
          },
          {
            id: "suspensionReactivationDateHasBeenCleared",
            message: "Suspension/Reactivation date(s) has been cleared.",
          },
          {
            id: "usersCanBeSuspendedUpTo90DaysInAdvanceOfTheCurrentDatePleaseEnterValidDate",
            message:
              "Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.",
          },
          {
            id: "youHaveEnteredAnInvalidSuspensionDate",
            message: "You have entered an invalid suspension date.",
          },
          {
            id: "youHaveEnteredAnInvalidReactivationDate",
            message: "You have entered an invalid reactivation date.",
          },
          {
            id: "pleaseEnterAReactivationDateThatIsWithinOneYearOfTheSuspensionDate",
            message:
              "Please enter a reactivation date that is within one year of the suspension date.",
          },
          {
            id: "theReactivationDateCannotBeTheSameAsTheSuspensionDate",
            message:
              "The reactivation date cannot be the same as the suspension date.",
          },
        ],
      },
    },
  
    SUBMIT_UPDATE_SUSPEND_USER_NEW_DETAILS: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      success: true,
      body: {
        header: "Issue New Password",
        ok: "OK",
        userName: "User Name",
        userId: "User Id",
        status:
          "REBECCA DONT DELETE has been suspended and is scheduled for reactivation on 11/30/2023. All pending wires, transfers and bill payments set up by this user will continue unless canceled by someone in your organization.",
        issuePasswordConfirmText: "A new password has been issued",
        errors: [
          {
            id: "pleaseEnterASsuspensionDateFirst",
            message: "Please enter a suspension date first.",
          },
          {
            id: "suspensionReactivationDateHasBeenCleared",
            message: "Suspension/Reactivation date(s) has been cleared.",
          },
          {
            id: "usersCanBeSuspendedUpTo90DaysInAdvanceOfTheCurrentDatePleaseEnterValidDate",
            message:
              "Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.",
          },
          {
            id: "youHaveEnteredAnInvalidSuspensionDate",
            message: "You have entered an invalid suspension date.",
          },
          {
            id: "youHaveEnteredAnInvalidReactivationDate",
            message: "You have entered an invalid reactivation date.",
          },
          {
            id: "pleaseEnterAReactivationDateThatIsWithinOneYearOfTheSuspensionDate",
            message:
              "Please enter a reactivation date that is within one year of the suspension date.",
          },
          {
            id: "theReactivationDateCannotBeTheSameAsTheSuspensionDate",
            message:
              "The reactivation date cannot be the same as the suspension date.",
          },
        ],
      },
    },
  
    SUBMIT_UPDATE_SUSPEND_USER_NEW_DETAILS_ALREADY_SUSPENDED: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      success: true,
      body: {
        header: "Issue New Password",
        ok: "OK",
        userName: "User Name",
        userId: "User Id",
        status: "hard token is scheduled to be reactivated on 12/02/2023.",
        issuePasswordConfirmText: "A new password has been issued",
        errors: [
          {
            id: "pleaseEnterASsuspensionDateFirst",
            message: "Please enter a suspension date first.",
          },
          {
            id: "suspensionReactivationDateHasBeenCleared",
            message: "Suspension/Reactivation date(s) has been cleared.",
          },
          {
            id: "usersCanBeSuspendedUpTo90DaysInAdvanceOfTheCurrentDatePleaseEnterValidDate",
            message:
              "Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.",
          },
          {
            id: "youHaveEnteredAnInvalidSuspensionDate",
            message: "You have entered an invalid suspension date.",
          },
          {
            id: "youHaveEnteredAnInvalidReactivationDate",
            message: "You have entered an invalid reactivation date.",
          },
          {
            id: "pleaseEnterAReactivationDateThatIsWithinOneYearOfTheSuspensionDate",
            message:
              "Please enter a reactivation date that is within one year of the suspension date.",
          },
          {
            id: "theReactivationDateCannotBeTheSameAsTheSuspensionDate",
            message:
              "The reactivation date cannot be the same as the suspension date.",
          },
        ],
      },
    },
  
    SUBMIT_UPDATE_SUSPEND_USER_NEW_DETAILS_FAILURE: {
      flag: false,
      httpStatusCode: 0,
      message: "",
      uiDisplayTime: 0,
      success: false,
      genericException: false,
      errors: [
        {
          name: "noChangesWereSpecified",
          value: "No changes were specified.",
        },
      ],
      body: {
        header: "Issue New Password",
        ok: "OK",
        userName: "User Name",
        userId: "User Id",
        issuePasswordConfirmText: "A new password has been issued",
        errors: [
          {
            id: "pleaseEnterASsuspensionDateFirst",
            message: "Please enter a suspension date first.",
          },
          {
            id: "suspensionReactivationDateHasBeenCleared",
            message: "Suspension/Reactivation date(s) has been cleared.",
          },
          {
            id: "usersCanBeSuspendedUpTo90DaysInAdvanceOfTheCurrentDatePleaseEnterValidDate",
            message:
              "Users can be suspended up to 90 days in advance of the current date.  Please enter a valid date.",
          },
          {
            id: "youHaveEnteredAnInvalidSuspensionDate",
            message: "You have entered an invalid suspension date.",
          },
          {
            id: "youHaveEnteredAnInvalidReactivationDate",
            message: "You have entered an invalid reactivation date.",
          },
          {
            id: "pleaseEnterAReactivationDateThatIsWithinOneYearOfTheSuspensionDate",
            message:
              "Please enter a reactivation date that is within one year of the suspension date.",
          },
          {
            id: "theReactivationDateCannotBeTheSameAsTheSuspensionDate",
            message:
              "The reactivation date cannot be the same as the suspension date.",
          },
        ],
      },
    },
  
    APPROVALS: {
      MAKERCHECKERAPPROVALS: {
        flag: false,
        httpStatusCode: 0,
        message: "",
        uiDisplayTime: 0,
        success: true,
        body: {
          header: "System Administration Tasks",
          adminIssuePasswordNote:
            "Enter your password and click Submit to generate a temporary password for the selected user",
          asofHeader: "Date ",
          asofDate: "Dec 11, 2023  at  2:06 PM EST",
          password: "Enter Password",
          cancel: "Cancel",
          submit: "Submit",
          ok: "OK",
          asof: "as of",
          noRecords: "The requested content or data is not available",
          newPasswordIssueText: "is about to be issued a new password",
          saTransactionRequest: "SA Transaction Request",
          userName: "User Name",
          initiatingSa: "Initiating SA",
          dateActionrequested: "Date Action Requested",
          details: "Details",
          requests: [
            {
              key: "536bd558-5f80-4a47-9d5b-19653dc16d98",
              saTranDescription: "Copy Entitlements",
              saTranType: "COPY_ENT",
              busName: "AJ BIZ CLASSICTEST",
              makerLName: "DND",
              makerFName: "Harsha",
              userLName: "Test1",
              userFName: "Alif",
              enrolledInMc: "Y",
              makerUserId: "091",
              makerTranTS: 1697811203313,
              siteCode: "",
              statusFlag: "P",
            },
            {
              key: "c06cbc83-fc2b-4b7a-bbb2-03093d1455a8",
              saTranDescription: "Bulk Copy Order",
              saTranType: "BULK_COPY_ENT",
              busName: "AJ BIZ CLASSICTEST",
              makerLName: "DND",
              makerFName: "Harsha",
              userLName: "Test User",
              userFName: "Alif",
              enrolledInMc: "Y",
              makerUserId: "091",
              makerTranTS: 1697811339563,
              siteCode: "",
              statusFlag: "P",
            },
            {
              key: "91f81dfd-f6c7-4fdd-a4e4-1bbd2067b2e8",
              saTranDescription: "Change Business Settings",
              saTranType: "BUSINESS",
              busName: "AJ BIZ CLASSICTEST",
              makerLName: "DND",
              makerFName: "Harsha",
              userLName: "DND",
              userFName: "Harsha",
              enrolledInMc: "Y",
              makerUserId: "091",
              makerTranTS: 1697814607537,
              siteCode: "",
              statusFlag: "P",
            },
            {
              key: "60cff07d-f6d6-4993-bcff-03eea0363496",
              saTranDescription: "Change User Information",
              saTranType: "MODIFY_USER",
              busName: "AJ BIZ CLASSICTEST",
              makerLName: "JANAGAM",
              makerFName: "MOUNIKA",
              userLName: "JANAGAM",
              userFName: "MOUNIKA",
              enrolledInMc: "Y",
              makerUserId: "415",
              makerTranTS: 1698047230225,
              siteCode: "",
              statusFlag: "P",
            },
            {
              key: "c301bc34-a493-45ce-a42a-6fe9c5052bee",
              saTranDescription: "Change User Account Entitlements",
              saTranType: "CHANGE_USER_ENTITLEMENTS",
              busName: "AJ BIZ CLASSICTEST",
              makerLName: "Padhee",
              makerFName: "Samir",
              userLName: "DontDel",
              userFName: "Yang",
              enrolledInMc: "Y",
              makerUserId: "603",
              makerTranTS: 1700124465402,
              siteCode: "",
              statusFlag: "P",
            },
            {
              key: "08fe28b4-c530-44d5-9238-6d79d5eaf0d8",
              saTranDescription: "Change User Information",
              saTranType: "SA_CHANGE_USER_INFORMATION",
              busName: "AJ BIZ CLASSICTEST",
              makerLName: "TEST1",
              makerFName: "REBECCA",
              userLName: "TEST1",
              userFName: "REBECCA",
              enrolledInMc: "Y",
              makerUserId: "380",
              makerTranTS: 1700797239401,
              siteCode: "",
              statusFlag: "P",
            },
            {
              key: "5502eece-d405-4223-acae-d15cadaa08c3",
              saTranDescription: "Change Business Settings",
              saTranType: "BUSINESS",
              busName: "AJ BIZ CLASSICTEST",
              userLName: "TEST11",
              userFName: "NK",
              timesApprv: 0,
              lvlOfApprv: 0,
              enrolledInMc: "Y",
              makerUserId: "392",
              makerTranTS: 1701460088592,
              siteCode: "",
              statusFlag: "P",
            },
          ],
          errors: [
            {
              id: "message-error",
              message: "Password Required",
            },
          ],
        },
      },
  
      MAKERCHECKERDETAILS: {
        "flag": false,
        "httpStatusCode": 0,
        "message": "",
        "uiDisplayTime": 0,
        "leftMenu": {
            "close": "Sign Off",
            "items": [
                {
                    "app": false,
                    "action": "#homePageFromSignIn",
                    "name": "Home",
                    "icon": "fa-home"
                },
                {
                    "app": false,
                    "action": "#depositAccountPage",
                    "name": "Deposit Accounts"
                },
                {
                    "app": false,
                    "action": "#creditAccountPage",
                    "name": "Credit Accounts"
                },
                {
                    "app": false,
                    "action": "#approvalsPage",
                    "name": "Approvals"
                },
                {
                    "app": false,
                    "action": "#transferMenuPage",
                    "name": "Transfers and Payments"
                },
                {
                    "app": false,
                    "action": "#adminFunctionMenuPage",
                    "name": "System Admin Functions"
                },
                {
                    "app": false,
                    "action": "#messagesMenuPage",
                    "name": "Messages"
                },
                {
                    "app": false,
                    "action": "#userAdministrationPage",
                    "name": "User Administration"
                },
                {
                    "app": false,
                    "action": "#help/adminFunctionsHelpPage",
                    "name": "Help: Admin Functions"
                }
            ]
        },
        "rightMenu": {
            "includeLocales": true,
            "close": "Close",
            "items": [
                {
                    "app": false,
                    "action": "#contactUsPage",
                    "name": "Contact Us",
                    "icon": "fa-phone"
                },
                {
                    "app": false,
                    "action": "#faqsPage",
                    "name": "FAQs",
                    "icon": "fa-info-circle"
                },
                {
                    "app": false,
                    "action": "#quickStartGuidePage",
                    "name": "Quick Start Guide",
                    "icon": "fa-info-circle"
                },
                {
                    "app": false,
                    "action": "#requireUserAgreementPage",
                    "name": "CitiBusiness&#174; Online User Agreement",
                    "icon": "fa-info-circle"
                },
                {
                    "app": false,
                    "action": "#privacyPage",
                    "name": "Privacy Statement",
                    "icon": "fa-info-circle"
                }
            ]
        },
        "success": true,
        "body": {
            "header": "System Admin Approvals",
            "adminIssuePasswordNote": "Enter your password and click Submit to generate a temporary password for the selected user",
            "copyEntitlementsOfUser": "Copy Entitlements of user",
            "asofHeader": "Date ",
            "password": "Enter Password",
            "cancel": "Cancel",
            "submit": "Submit",
            "ok": "OK",
            "changes": "Changes",
            "notChanged": "Not Changed",
            "newPasswordIssueText": "is about to be issued a new password",
            "deleteApprovalWorkflow": "Delete Approval Workflow",
            "transactionsAdditional": [
                {
                    "header": "Info-Reporting -Today's",
                    "saEntitlementNameValuesTodayActivityVo": {
                        "infoReportingActivityVo": {
                            "description": "Activity",
                            "before": "N",
                            "after": "N",
                            "changed": false,
                            "order": 0,
                            "type": "string"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "Info-Reporting -Recent",
                    "saEntitlementNameValuesRecentActivityVo": {
                        "infoReportingActivityVo": {
                            "description": "Activity",
                            "before": "Y",
                            "after": "Y",
                            "changed": false,
                            "order": 0,
                            "type": "string"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "Cont Disb",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "Lock-box",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "ARP Level 1",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "ARP Level 2 ",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "ARP Level 3  ",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "ACH ",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "Wire Transfer ",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "Initiator, Approver",
                            "changed": true,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": true
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "10000",
                            "changed": true,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": true
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "10000",
                            "changed": true,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": true
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "10000",
                            "changed": true,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": true
                    },
                    "changed": true,
                    "showNotChangedFields": true,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "Internal Transfer ",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "Bill Payment ",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "Initiator, Approver",
                            "after": "Initiator, Approver",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "909889",
                            "after": "909889",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "689083",
                            "after": "689083",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "909889",
                            "after": "909889",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "ARP User Positive Pay",
                    "saEntitlementNameValuesPositivePayVo": {
                        "positivePayVo": {
                            "description": "Positive Pay",
                            "before": "N",
                            "after": "N",
                            "changed": false,
                            "order": 0,
                            "type": "string"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "ARP User Upload ",
                    "saEntitlementNameValuesUploadVo": {
                        "uploadVo": {
                            "description": "Upload",
                            "before": "N",
                            "after": "N",
                            "changed": false,
                            "order": 0,
                            "type": "string"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "ARP User Download ",
                    "saEntitlementNameValuesDownloadVo": {
                        "downloadVo": {
                            "description": "Download",
                            "before": "N",
                            "after": "N",
                            "changed": false,
                            "order": 0,
                            "type": "string"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "ARP User MIV ",
                    "saEntitlementNameValuesManualVo": {
                        "manualVo": {
                            "description": "Manual Issue",
                            "before": "N",
                            "after": "N",
                            "changed": false,
                            "order": 0,
                            "type": "string"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "Instant Pay ",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                },
                {
                    "header": "",
                    "saEntitlementNameValues1LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2LimitVo": {
                        "limitSaNameValueVo": {
                            "description": "One Time Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues1RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 1",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValues2RecurringVo": {
                        "limitSaNameValueVo": {
                            "description": "Recurring Limit 2",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesRolesVo": {
                        "rolesVo": {
                            "description": "Role",
                            "before": "None",
                            "after": "None",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesApprovalLimitVo": {
                        "approvalLimitVo": {
                            "description": "Approval Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesNoApprovalNeededUpToVo": {
                        "noApprovalNeededUpToVo": {
                            "description": "No Approval Needed Up To",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "saEntitlementNameValuesDailyLimitVo": {
                        "dailyLimitVo": {
                            "description": "Daily Limit",
                            "before": "0",
                            "after": "0",
                            "changed": false,
                            "order": 0,
                            "type": "dollar"
                        },
                        "changed": false
                    },
                    "changed": false,
                    "showNotChangedFields": false,
                    "showNotChangedInfoReporting": false
                }
            ],
            "saTranDescription": "Change User Account Entitlements",
            "transactionDetails": "Transaction Details",
            "after": "After",
            "before": "Before",
            "userInformation": "User Information",
            "saTranId": "247479",
            "saTranType": "CHANGE_USER_ENTITLEMENTS",
            "makerUserId": "856",
            "checkerUserId": "884",
            "initiatiorCantApproveMessage": "You cannot approve items that you have initiated.",
            "approve": "Approve",
            "reject": "Reject",
            "toUsers": "To User(s)",
            "forAccountNumbers": "For Account Number(s)",
            "user": "User",
            "accountNumber": "Account Number",
            "removed": "Removed",
            "added": "Added",
            "billPay": "Bill Pay",
            "wireTransfer": "Wire Transfer",
            "aCHOrigination": "ACH Origination",
            "internalTransfer": "Internal Transfer",
            "errors": [
                {
                    "id": "message-error",
                    "message": "Password Required"
                }
            ]
        }
    },
  
      MAKERCHECKERDETAILSSUBMIT:{
        "flag": false,
        "httpStatusCode": 0,
        "message": "",
        "uiDisplayTime": 0,
        "leftMenu": {
            "close": "Sign Off",
            "items": [
                {
                    "app": false,
                    "action": "#homePageFromSignIn",
                    "name": "Home",
                    "icon": "fa-home"
                },
                {
                    "app": false,
                    "action": "#depositAccountPage",
                    "name": "Deposit Accounts"
                },
                {
                    "app": false,
                    "action": "#creditAccountPage",
                    "name": "Credit Accounts"
                },
                {
                    "app": false,
                    "action": "#approvalsPage",
                    "name": "Approvals"
                },
                {
                    "app": false,
                    "action": "#transferMenuPage",
                    "name": "Transfers and Payments"
                },
                {
                    "app": false,
                    "action": "#adminFunctionMenuPage",
                    "name": "System Admin Functions"
                },
                {
                    "app": false,
                    "action": "#messagesMenuPage",
                    "name": "Messages"
                },
                {
                    "app": false,
                    "action": "#userAdministrationPage",
                    "name": "User Administration"
                },
                {
                    "app": false,
                    "action": "#help/adminFunctionsHelpPage",
                    "name": "Help: Admin Functions"
                }
            ]
        },
        "rightMenu": {
            "includeLocales": true,
            "close": "Close",
            "items": [
                {
                    "app": false,
                    "action": "#contactUsPage",
                    "name": "Contact Us",
                    "icon": "fa-phone"
                },
                {
                    "app": false,
                    "action": "#faqsPage",
                    "name": "FAQs",
                    "icon": "fa-info-circle"
                },
                {
                    "app": false,
                    "action": "#quickStartGuidePage",
                    "name": "Quick Start Guide",
                    "icon": "fa-info-circle"
                },
                {
                    "app": false,
                    "action": "#requireUserAgreementPage",
                    "name": "CitiBusiness&#174; Online User Agreement",
                    "icon": "fa-info-circle"
                },
                {
                    "app": false,
                    "action": "#privacyPage",
                    "name": "Privacy Statement",
                    "icon": "fa-info-circle"
                }
            ]
        },
        "success": false,
        "genericException": false,
        "errors": [
            {
                "name": "transfer-exception",
                "value": "The security information you entered is not valid. Please try again"
            }
        ]
    },
    },
  };
  
  export default SYSTEM_ADMINISTRATION_MOCKS;