import __httpApi from './api.core';
import { RequestMethod } from './api.core';
import kGENERAL from "../common/constants/GENERAL";
import kPIWIK from "../common/constants/PIWIK";
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { getUserAgent1 } from '../utils/api.util';
import { DEVICE_TYPE } from '../device/xdevice';
// import  
let WIRES_MOCKS: any
let ABA_DETAILS_MOCKS: any

//  from './mocks/wires.mock';

export interface WiresResponse {

}

export class WiresApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        // const file  = 
        // const mockPath = isProd ? './mocks/prod.mock.ts':'./mocks/wires.mock';
        // const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/wires.mock.copy.ts').default
        // const m_aba = require(isProd ? './mocks/prod.mock.ts' : './mocks/aba-details.mock.copy.ts').default

        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/wires.mock.ts').default
        const m_aba = require(isProd ? './mocks/prod.mock.ts' : './mocks/aba-details.mock.ts').default

        WIRES_MOCKS = m
        ABA_DETAILS_MOCKS = m_aba

    }

    wiresDetialsPage(params: { [key: string]: any }, callBack?: Function) {
        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    data: params,
                    config: {},
                    headers: {},
                    status: 200,
                    statusText: "OK",
                })
            }, 300)
        });
    }

    plannedAndPendingWiresListPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wireTransfers/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'plannedAndPendingWiresListPage',
            WIRES_MOCKS?.PLANNED_PENDING_LIST, null, 120000) as Promise<AxiosResponse<WiresResponse>>;
    }
    actionOnWireTransaction(refId: string, action: 'SUSPEND' | 'REACTIVATE' | 'CANCEL', callBack?: Function) {
        const payload = {
            transferReferenceNumber: refId,
            transferIndicator: "WIRE_TRANSFER",
            actionType: action
        }
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}/transfers/pendingActivity/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            payload,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'actionOnWireTransaction',
            WIRES_MOCKS?.ACTION_SUSPEND_TRANSACTION) as Promise<AxiosResponse<WiresResponse>>;
    }


    completedWiresPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wireTransfers/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'completedWiresPage',
            WIRES_MOCKS?.COMPLETED_LIST_PAGE_2, null, 120000) as Promise<AxiosResponse<WiresResponse>>;
    }



    accountsInformationPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}user/accounts/information/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'accountsInformationPage',
            WIRES_MOCKS?.ACCOUNTS) as Promise<AxiosResponse<WiresResponse>>;
    }
    // WIRE Initiate

    setUpWireAccountPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}setUpWireAccountPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireAccountPage',
            WIRES_MOCKS?.WIRE_ACCOUNT_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    setUpWireSchedulingAmountPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}setUpWireSchedulingAmountPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireSchedulingAmountPage',
            WIRES_MOCKS?.WIRE_SCHEDULING_AMOUNT_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }


    //https://mobileuat.citibusinessonline.com/setUpWireGetBankFromABA/16/en
    //{aba:"123456789"}
    getBankFromABA(aba: string, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireGetBankFromABA/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            { 'aba': aba },
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireUSBankSearchPage',
            WIRES_MOCKS?.WIRE_SEARCH_ABA) as Promise<AxiosResponse<WiresResponse>>;
    }

    //{stateToSearch: "CO", searchFilterName: "J.P", searchFilterCondition: "CONTAINS"}
    setUpWireUSBankSearchPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireUSBankSearchPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireUSBankSearchPage',
            WIRES_MOCKS?.WIRE_US_SEARCH_BANK_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    //{"key":"212d207b-ffe9-414c-bfcd-cf3cf09ebebe","futureDate":"3/1/2023","startDay":1,"otherDay":null,"transferCount":"","stopDate":"","timeEnum":"NONE","scheduleTypeEnum":"IMMEDIATE","frequencyTypeEnum":"IMMEDIATE","fixedAmount":"1.00","flextAmountTypeEnum":"NON_FLEX"}
    setUpWireConfirmTransferPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireConfirmTransferPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireConfirmTransferPage',
            WIRES_MOCKS?.WIRE_CONFIRM_TRANSFER_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    //{"citiKey":"b0e3303d-c74e-4afc-bb99-df8920528d42","transferAccountResponseVo":{"accountName":"ACH","accountType":"CHECKING","accountTypeName":"Checking","balance":3557.36,"balanceAvailable":true,"fimp":"000","key":"212d207b-ffe9-414c-bfcd-cf3cf09ebebe","maskedNumber":"-2312","personalAccount":false},"beneficiaryVo":{"checkOrWireEnum":"WIRE_TRANSFER","beneficiaryName":"Test","beneficiaryAddress1":"No address","beneficiaryAddress2":"No address","beneficiaryAddress3":"","beneficiaryPhone":"1234567890","beneficiaryAccount":"123456789"},"bankVo":{"bankTypeEnum":"US_BANK","aba":"307070021","bankName":"AIR ACADEMY FCU","bankAddress":"","bankCity":"Colorado","bankState":"Colorado","bankStateCode":"CO","beneCurr":" ","bankInfo":"B"},"specialInstructionsVo":{"specialInstruction1":"","specialInstruction2":"","specialInstruction3":"","statementDesc":"","customerReferenceNumber":"","additionalReference":""},"wireScheduleVo":{"scheduleTypeEnum":"IMMEDIATE","futureDate":"3/1/2023","frequencyTypeEnum":"IMMEDIATE","startMonth":2,"startDay":1,"otherDay":null,"transferCount":"","stopDate":"","timeEnum":"NONE"},"countryCurrencyVo":{"currencyCodePlus":"USDT","currencyCountry":"USUSD","destinationaCountry":"US"},"wireAmountVo":{"amountType":"fixed","fixedAmount":"1.00","amountDesignationEnum":"AS_USD","flextAmountTypeEnum":"NON_FLEX"},"passwordRequired":true}
    setUpWireSubmitPage(params: { [key: string]: any }, callBack?: Function, akamaiData?: string) {
        let headers = null;
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiData) && akamaiData.length > 0) {
            headers = {
                "x-acf-sensor-data": akamaiData,
                "user-agent1": getUserAgent1()
            }
        }

        let fixedAmount = params?.wireAmountVo?.fixedAmount;
        if (_.isString(fixedAmount) && fixedAmount.includes(',')) {
            fixedAmount = fixedAmount.replace(/,/g, '');

            const wireAmountVo = { ...params.wireAmountVo, fixedAmount };
            params = { ...params, wireAmountVo }
        }

        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireSubmitPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            headers,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireSubmitPage',
            WIRES_MOCKS?.WIRE_SUBMIT_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    //Wire using Model

    setUpWireUsingModelPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireUsingModelPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireUsingModelPage',
            WIRES_MOCKS?.WIRE_USING_MODEL_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    setUpWireConfirmTransferUsingModelPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireConfirmTransferUsingModelPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireConfirmTransferUsingModelPage',
            WIRES_MOCKS?.WIRE_FOREIGN_TEMPLATE_DETAIL) as Promise<AxiosResponse<WiresResponse>>;
    }

    //{"request":"send","password":"Welcome4","token":"123456","citiKey":"d2932a43-24cf-4c31-8bb6-5c427b4b9888","autoGenerateSoftToken":false,"autoTokenCode":"-1","clientType":"browser"}
    setUpWireInstructionSummaryPage(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}setUpWireInstructionSummaryPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpWireInstructionSummaryPage',
            WIRES_MOCKS?.WIRE_SETUP_INSTRUCTION_SUMMARY_PAGE) as Promise<AxiosResponse<WiresResponse>>;
    }

    getCountryCurrencylist(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wiretransfer/country/currency/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getCountryCurrencylist',
            WIRES_MOCKS?.WIRE_COUNTRY_LIST) as Promise<AxiosResponse<WiresResponse>>;
    }

    getExchangeRate(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wiretransfer/exchangeRate/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getExchangeRate',
            WIRES_MOCKS?.GET_EXCHANGE_RATE_34) as Promise<AxiosResponse<WiresResponse>>;
    }

    getEntitlementStatus(callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}wireTransfer/entitlements/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getEntitlementStatus',
            WIRES_MOCKS?.ENTITLEMENT_STATUS) as Promise<AxiosResponse<WiresResponse>>;
    }

    getInformationAccConsumer(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wireTransfer/accounts/information/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getInformationAccConsumer',
            WIRES_MOCKS?.ENTITLEMENT_STATUS) as Promise<AxiosResponse<WiresResponse>>;
    }

    getABADetails(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wiretransfer/aba/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getABADetails',
            ABA_DETAILS_MOCKS?.ABA_DETAILS) as Promise<AxiosResponse<WiresResponse>>;
    }

    getRouteInformation(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wiretransfer/route/information/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getRouteInformation',
            WIRES_MOCKS?.ROUTE_INFORMATION) as Promise<AxiosResponse<WiresResponse>>;
    }

    getPurposeCodeInformation(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wiretransfer/purposeCode/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getPurposeCodeInformation',
            WIRES_MOCKS?.PURPOSE_CODE_INFORMATION_EMPTY) as Promise<AxiosResponse<WiresResponse>>;
    }

    getForeignWireTemplates(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wiretransfer/template/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getForeignWireTemplates',
            WIRES_MOCKS?.WIRE_FOREIGN_TEMPLATE) as Promise<AxiosResponse<WiresResponse>>;
    }

    setUpForeignWireSubmitPage(params: { [key: string]: any }, callBack?: Function, akamaiData?: string) {
        let headers = null;
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiData) && akamaiData.length > 0) {
            headers = {
                "x-acf-sensor-data": akamaiData,
                "user-agent1": getUserAgent1()
            }
        }

        let fixedAmount = params?.wireAmountVo?.fixedAmount;
        if (_.isString(fixedAmount) && fixedAmount.includes(',')) {
            fixedAmount = fixedAmount.replace(/,/g, '');

            const wireAmountVo = { ...params.wireAmountVo, fixedAmount };
            params = { ...params, wireAmountVo }
        }

        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}threatMatrix/query/submission/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            headers,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'setUpForeignWireSubmitPage',
            WIRES_MOCKS?.FOREIGN_WIRE_SUBMIT) as Promise<AxiosResponse<WiresResponse>>;
    }

    getUserAccounts(header: { [key: string]: any }, params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}user/accounts/information/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            header,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getUserAccounts',
            WIRES_MOCKS?.USER_ACCOUNTS) as Promise<AxiosResponse<WiresResponse>>;
    }

    getThreatMatrixSession(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}threatMatrix/session/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getThreatMatrixSession',
            WIRES_MOCKS?.THREATMATRIX_SESSION) as Promise<AxiosResponse<WiresResponse>>;
    }

    foreignWireTransferInitiation(params: { [key: string]: any }, callBack?: Function) {
        let mock = WIRES_MOCKS?.FOREIGN_WIRE_TRANSFER_INITIATION_3
        if (params?.wireTransfers && params?.wireTransfers?.length > 0 && params?.wireTransfers[0]?.insufficientFundIndicator) {
            mock = WIRES_MOCKS?.FOREIGN_WIRE_TRANSFER_INITIATION_1
        }

        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wiretransfer/initiation/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'foreignWireTransferInitiation',
            mock) as Promise<AxiosResponse<WiresResponse>>;
    }

    getUserInfo(callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}user/information/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getUserInfo',
            WIRES_MOCKS?.FOREIGN_WIRE_USER_INFO) as Promise<AxiosResponse<WiresResponse>>;
    }

    getWireTransferInfo(params: { [key: string]: any }, callBack?: Function) {
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}wireTransfers/retrieve/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.WIRES,
            'getWireTransferInfo',
            WIRES_MOCKS?.FOREIGN_WIRE_TRANSFER_INFO) as Promise<AxiosResponse<WiresResponse>>;
    }

}

const __instance__ = new WiresApi();

export default __instance__;
