import { useEffect, useState } from "react";
import { Modal } from "@citi-icg-172888/icgds-react";
import Button from "../button/button";
import __constants from "../../common/constants";
import "./custom-modal.style.scss"

interface CustomModalProps {
    userName?: string,
    title?: string,
    description?: string,
    descriptionArray?: string[],
    show: boolean,
    onContinue?: Function,
    continueButtoTitle?: string,
    onCancel?: Function,
    cancelButtoTitle?: string,
    showLoading?: boolean,
    cancellable?: boolean,
    onClose?: Function,
    isCloseable?: boolean
}

const CustomModal = (p: CustomModalProps) => {
    const [isCancellable, setIsCancellable] = useState(true);

    useEffect(() => {
        setIsCancellable((p.cancellable !== undefined ? p.cancellable : true))
    }, [p.cancellable]);

    const onContinueClick = () => {
        if (p.onContinue) p.onContinue()
    }

    const onCancelClick = () => {
        if (p.onCancel) p.onCancel()
    }

    const onCloseClick = () => {
        if (p.onClose) p.onClose()
    }

    const renderTitle = () => {
        return <>
            <div
                className="title-container"
                tabIndex={0}
                aria-label={`${(p.userName !== undefined && p.userName.length > 0) ? p.userName : ''} ${p.title}`}>

                {p.userName !== undefined && p.userName.length > 0
                    ? <span aria-hidden="true">{p.userName.length > 0 ? p.userName : null}</span>
                    : null}

                <p aria-hidden="true" className={`text-medium custom-modal-text ${(p.description !== undefined || p.descriptionArray !== undefined) ? 'bold' : ''}`}>{p.title}</p>

                {p.description !== undefined && p.description !== null
                    ? <p
                        tabIndex={0}
                        className='text-small custom-modal-description'
                        dangerouslySetInnerHTML={{ __html: p.description }}
                    />
                    : null}

                {p.descriptionArray !== undefined && p.descriptionArray !== null
                    ?
                    <div className="custom-modal-description-array-container">
                        {p.descriptionArray.map((desc, index) => {
                            return <p
                                key={index}
                                tabIndex={0}
                                className='text-small custom-modal-description'
                                dangerouslySetInnerHTML={{ __html: desc }}
                            />
                        })}
                    </div>
                    : null}
            </div>
        </>
    }

    const uiRenderFooter = () => {

        return <div className="footer-wrap">
            <div className="footer-button-wrap">
                {isCancellable &&
                    <Button outline
                        disabled={p.showLoading}
                        style={{ color: '#0066b3', borderColor: '#0066b3' }}
                        onClick={onCancelClick}>{p.cancelButtoTitle ? p.cancelButtoTitle : __constants.CANCEL}
                    </Button>
                }
                <Button color={'primary'}
                    disabled={p.showLoading}
                    onClick={onContinueClick}
                    showLoading={p.showLoading}
                >
                    {p.continueButtoTitle ? p.continueButtoTitle : __constants.CONTINUE}
                </Button>
            </div>
        </div>
    }

    const ui = () => {
        return <Modal
            centered
            visible={p.show}
            width='90%'
            wrapperClass="custom-modal-container"
            title={renderTitle()}
            closable={p.isCloseable}
            onClose={onCloseClick}
            footer={uiRenderFooter()}>
        </Modal>
    }


    return ui();
}


export default CustomModal;