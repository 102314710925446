const kLOANS = {
    LOAN_ACTIVITIES: 'Loan Activities',
    EDIT_NAME: 'Edit Name',
    NAME: 'Name',
    EDIT_NAME_CHECK_LENGTH: "The length of name is limited to 10 ",
    MORE_OPTIONS: 'More Options',
    ACCOUNTS_OVERVIEW: 'Accounts Overview',
    ACCOUNTS: 'accounts',
    ACCOUNT: 'account',
    ACCOUNT_NUMBER: 'Account Number',
    NO_LOAN_ACCOUNT_TEXT_1: 'We can now display your loan accounts in CitiDirect® Commercial Banking.',
    NO_LOAN_ACCOUNT_TEXT_2: 'Contact your RM to request loan access to view, manage, pay and drawdown on your loans all in one place.',
    NO_ACTIVITY_FOUND: "No activity found.",
    LOC_TOOLTIP_1: 'This loan number tracks the overall availability of the loan facility and may be the number on your fee billing statement.',
    LOC_TOOLTIP_2: 'This loan number tracks the outstanding principal balance and will typically be the number on your interest and/or principal billing statements.',
};

export default kLOANS;
