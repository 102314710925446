const kFX_PULSE = {
        CASH: 'Cash',
        YOU_SELL: 'You Sell',
        YOU_BUY: 'You Buy',
        LENDING: 'Lending',
        CURRENCY_RATES: 'Currency Rates',
        CURRENCY_HOLIDAYS: 'Currency Holidays',
        CURRENCY_RATES_NOTES: 'Please note, the FX Rates displayed are non-tradeable indicative rates and currency conversions are based on the rates displayed. To execute trades using live rates, please login to CitiFX Pulse.',
        SELECT_COMPANY: 'Select a Company',
        VIEW_MORE: 'View more',
        ALL_CURRENCIES: 'All Currencies',
        CURRENCIES: 'Currencies',
        REQUEST_ACCESS_TO_CITIFX: 'Request access to CitiFX Pulse',
        WATCH_CITI_FX_PULSE_DEMO: 'Watch Citi FX Pulse Demo',
        TOKEN_LOGIN_NOTE: 'Login with token is required to show you this content',
        ERROR: {
            API_Error: "This dashboard is currently unavailable. Please try again later",
            NO_CURRENCIES_SELECTED: "No currencies selected",
            NO_HOLIDAY: "There are no holidays available for the selected currencies.",
        }
};

export default kFX_PULSE;
