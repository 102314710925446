const kGENERAL = {
    APP_VERSION_DIR: "17",
    LANG: "en",
    REACT_ABC: "/",
    DEMO: "",

    REQUIRED: "Required",
    APPLY: "Apply",
    CHARACTERS: "Characters",

   //Button
   CANCEL: "Cancel",
   OK:'OK',
   RESET: "Reset",
   SUBMIT: "Submit",
   DONE: "Done",
   CONTINUE: "Continue",
   RESTART: "Restart",


    //Request
    REQ_FAILED: "Service unavailable currently. Please try again later.",

      //No data
    NO_DATA: "No Data Available.",
    NO_FILTERED_DATA: "No Result Found. Please change your filters and try again",
    

    FILTER_PARAMS: {
        MIN_AMOUNT: 'minimumAmount',
        MAX_AMOUNT: 'maximumAmount',
        START_DATE: 'startDate',
        END_DATE: 'endDate'
    },
};

export default kGENERAL;
